import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import 'slick-carousel/slick/slick.css';

import { PageItemState } from '@common/react/store/PageItem';
import { Lang } from '@common/typescript/objects/Lang';
import LinkWithLang from '@common/react/components/UI/lang/LinkWithLang/LinkWithLang';
import ServerPageProvider from '@common/react/components/UI/lang/SeverPageProviderWithLang/ServerpageProviderWithLang';
import ResizeObserverContainer from '@common/react/components/UI/ResizeObserverContainer/ResizeObserverContainer';

import { BottomAreas } from '@app/components/UI/BottomAreas/BottomAreas';
import { ReviewCountersComponent } from '@app/components/UI/ReviewCounters/ReviewCounters';
import { capitalizeFirstLetter } from '@app/components/Utils';
import OfficeInfoList from '@app/components/Pages/Home/OfficeInfoBlock';
import '@app/scss/pages/home.scss';
import { HomePage } from '@app/objects/Pages/HomePage';
import DoctorSlider from '@app/components/Pages/Home/DoctorSlider';
import ContactUsForm from '@app/components/Forms/ContactUs/ContactUsForm';
import '@app/scss/components/slick-theme.scss';
import Reviews from '@app/components/UI/Reviews/Reviews';
import Honors from '@app/components/Pages/Home/Honors';
import HomeReviews from '@app/components/Pages/Home/HomeReviews';
import { Honor } from '@app/objects/Honor';

interface HomeInnerProps {
	home: PageItemState<HomePage>;
}

interface Service {
	title: string;
	image: string;
	imageWebp: string;
	link?: string;
}

type Props = HomeInnerProps;

const DesktopContent: React.FC<{ honors: Array<Honor>, body: string | undefined }> = ({ honors, body }) => {
	const [isMobile, setIsMobile] = React.useState(true);

	return <ResizeObserverContainer
		onResize={() => {
			setIsMobile(window.innerWidth <= 768);
		}}
	>
		{isMobile ? null : <>
			{body ? <div className="clearfix container mobile-hidden">
				<div className="external-html" dangerouslySetInnerHTML={{ __html: body }} />
			</div> : null}
			<Honors honors={honors} />
		</>}
	</ResizeObserverContainer>;
};

const HomeInner: React.FC<Props> = ({
	home,
}) => {
	const { t, i18n } = useTranslation();

	const {
		counters = {
			bby: 0,
			yelp: 0,
			google: 0,
			facebook: 0,
			averageBby: 0,
			averageYelp: 0,
			averageGoogle: 0,
			averageFacebook: 0,
		},
		reviews = [],
		doctors = [],
		locations = [],
		honors = [],
		menuHome = [],
	} = home.page ? home.page.item : {};

	const langString: 'En' | 'Es' = capitalizeFirstLetter(i18n.language);
	const body = home.page?.langs.find((item: any) => item?.lang === langString)?.body;

	const services = React.useMemo(() => {
		const services: Array<Partial<Service>> = [
			{
				image: '/amp-assets/images/1.webp',
				imageWebp: '/amp-assets/images/1_m.webp',
			},
			{
				image: '/amp-assets/images/3.webp',
				imageWebp: '/amp-assets/images/3_m.webp',
			},
			{
				image: '/amp-assets/images/2.webp',
				imageWebp: '/amp-assets/images/2_m.webp',
			},
			{
				image: '/amp-assets/images/4.webp',
				imageWebp: '/amp-assets/images/4_m.webp',
			},
		];

		menuHome.forEach((menu, index) => {
			if (index >= services.length) return;

			services[index].title = (Lang[langString] === Lang.Es) ? menu.shortNameEs : menu.shortNameEn;
			services[index].link = `/${menu.fullPath}`;
		});

		return services;
	}, [menuHome]);

	return <div className="home-page">
		<div className="home-page__banner__wrapper">
			<div className="container home-page__container home-page__banner">
				<img className="home-page__logo mobile-hidden" src="/images/logo.webp" alt="Beauty Becomes Logo" />
				<div className="">
					<div className="home-page-headline">
						<h1>
							<Trans i18nKey="site.homeHeadline" />
						</h1>
					</div>
				</div>
				{body ? <div className="clearfix mobile-show">
					<div className="external-html" dangerouslySetInnerHTML={{ __html: body }} />
				</div> : null
				}
				<div className="row home-page-sections">
					{services.map((item, index) =>
						<div key={index} className="col-xs-3 col-md-3">
							<LinkWithLang to={item.link || ''} className="home-page-sections-item">
								<picture>
									<source media="(max-width: 768px)" srcSet={item.imageWebp} />
									<source media="(min-width: 769px)" srcSet={item.image} />
									<img className="home-page-sections-item__image" srcSet={item.image} alt="" />
								</picture>
								<div className="home-page-sections-item__title">
									{item.title}
								</div>
							</LinkWithLang>
						</div>)}
				</div>
			</div>
		</div>
		<DesktopContent honors={honors} body={body} />
		<div className="container">
			<LinkWithLang to={(Lang[langString] === Lang.Es) ? '/practica/doctors' : '/practice/doctors'} className="contacts contacts_title">
				<h2>{t('site.Our Team')}</h2>
			</LinkWithLang>
			<div className="row doctor-card-list">
				<DoctorSlider doctors={doctors} />
			</div>
			<div className="contacts">
				<h3>{t('site.Schedule an Appointment Now')}</h3>
				<ContactUsForm className="general-form" />
			</div>
		</div>
		<div className="contacts contacts_title">
			<h2>{t('site.Reviews')}</h2>
		</div>
		<div className="container">
			<ReviewCountersComponent counters={counters} />
		</div>
		<div className="mobile-show container">
			<Reviews reviews={reviews} />
		</div>
		<div className="contacts contacts_title mobile-hidden">
			<h2>{t('site.Reviews from our clients')}</h2>
		</div>
		<HomeReviews reviews={reviews} />
		<div className="contacts contacts_title">
			<h2>{t('site.Contacts')}</h2>
		</div>
		<div className="container">
			<div className="home-page-offices row">
				<OfficeInfoList />
			</div>
		</div>
		<BottomAreas areas={locations} />
	</div>;
};

const HomeInnerWithPageInfo = HomeInner;

const Home: React.FC = () => {
	return (
		<ServerPageProvider
			path="home"
			inner={(page) => (<HomeInnerWithPageInfo home={{ page } as any} />)}
		/>
	);
};

export default Home;
